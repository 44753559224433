import Layout from '../components/layout'
import Seo from '../components/seo'
import {StaticImage} from 'gatsby-plugin-image'
import React from 'react'
import useStations from '../utils/hooks/useStations'

export default function CreateDashboard() {
  const {station} = useStations()
  return (
    <Layout station={station}>
      <Seo title="Create Dashboard" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <StaticImage
              src="../assets/images/create-dashboard-banner.jpg"
              alt="create dashboard banner"
              className="rounded-md"
            ></StaticImage>
            <div className="mt-8">
              <h1>Create Your NEWA 3.0 Profile</h1>

              <p>
                Create your NEWA 3.0 profile to take advantage of useful
                features including quick access pre-elected weather stations and
                resources, permanent database storage of important biofix data
                or model settings, and more.{' '}
                <i>We do not share or sell your personal information.</i>
              </p>

              <h2>
                Get started with NEWA 3.0 by watching these Quickstart guides
              </h2>

              <h4>
                Create and Configure Your User Profile{' '}
                <a
                  href="https://newa.zendesk.com/hc/en-us/articles/4417360459799"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Watch now (3:00)
                </a>
              </h4>

              <h4>
                Dashboard Navigation.{' '}
                <a
                  href="https://newa.zendesk.com/hc/en-us/articles/4417346595991"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Watch now (3:19)
                </a>
              </h4>

              <br></br>
              <h2>Talk to us!</h2>

              <p>
                Reach out with to the NEWA Help Desk at any time with a note to{' '}
                <a href="mailto:support@newa.zendesk.com">
                  support@newa.zendesk.com
                </a>{' '}
                or submit an{' '}
                <a
                  href="https://newa.zendesk.com/hc/en-us/requests/new?ticket_form_id=360003631693"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  online request
                </a>
                . An electronic work ticket is generated that is tracked until
                we find an answer or resolution for you.
              </p>
            </div>
          </div>

          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
            <h2>Have a question?</h2>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Email the NEWA Help Desk
              </a>
            </p>
            <p>
              <a href="mailto:support@newa.zendesk.com" className="flex">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 mr-1 mail"
                >
                  <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                  <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                </svg>
                support@newa.zendesk.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
